import { renderNavSearch, renderDialogSearch } from 'rs-search-ui';

const {
  searchKey,
  engineName,
  searchAction,
  endpointBase,
  placeholder,
  elasticQuery,
  elasticAutocompleteQuery
} = window.HEADER_SEARCH_CONTENT;

const searchQuery = elasticQuery || {
  result_fields: {
    title: {
      snippet: {
        fallback: true
      },
      raw: {}
    }
  }
};

const autocompleteQuery = elasticAutocompleteQuery || {
  results: {
    result_fields: {
      title: { snippet: { size: 100, fallback: true }, raw: {} },
      url: { raw: {} }
    }
  },
  suggestions: {
    size: 5,
    types: {
      documents: {
        title: { raw: {} }
      }
    }
  }
};

const navElement = document.getElementById('nav_search');
renderNavSearch(
  navElement,
  searchKey,
  engineName,
  endpointBase,
  searchQuery,
  autocompleteQuery,
  placeholder,
  searchAction,
  'subtle',
  'u-spacingInlineXl'
);

const dialogElement = document.getElementById('dialog_search');
const dialogButton = document.getElementById('dialog_search_button');
renderDialogSearch(
  dialogElement,
  dialogButton,
  searchKey,
  engineName,
  endpointBase,
  searchQuery,
  autocompleteQuery,
  placeholder,
  searchAction
);
